import SpinWheel from "../../components/SpinWheel/SpinWheel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Campaign } from "../models/campaign";
import agent from "../api/agent";
import CenteredCard from "../layout/CenteredCard";
import { env } from "../../app/utils/env";

export default function GameBoard() {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  let background = "";
  let backgroundSize = "";
  if (campaign?.wheel.screenBg) {
    if (campaign?.wheel.screenCenterBg) {
      background = `url(${env + campaign.wheel.screenCenterBg}) center 1300px no-repeat, url(${env + campaign.wheel.screenBg}) center center no-repeat`;
      backgroundSize = "auto, cover";
    } else {
      background = `url(${env + campaign.wheel.screenBg}) center center no-repeat`
      backgroundSize = "cover";
    }
  } else {
    backgroundSize = "auto";
    if (campaign?.wheel.screenCenterBg) {
      background = `url(${env + campaign.wheel.screenCenterBg}) center 600px no-repeat`;
    } else {
      background = 'none';
    }
  }

  const fetchCampaign = async () => {
    if (id) {
      try {
        const fetchedCampaign = await agent.Campaigns.details(id);
        fetchedCampaign.wheel.slices.sort((a, b) => a.listOrder - b.listOrder);
        fetchedCampaign.wheel.slices.forEach(slice => {
          slice.probability = slice.probability ? parseFloat(slice.probability.toFixed(3)) : 0;
        });
        setCampaign(fetchedCampaign);
      } catch (error) {
        console.error("Failed to fetch campaign:", error);
      }
    } else {
      console.error("No campaign id provided");
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, [id]);

  const handleStockDepletion = () => {
    fetchCampaign(); // Refetch the campaign data to update probabilities
  };

  if (!campaign) {
    return <CenteredCard>Yükleniyor...</CenteredCard>;
  }

  return (
    <>
      {campaign.customCSS && (
        <style>
          {campaign.customCSS}
        </style>
      )}
      <main
        className="game-screen"
        style={{
          backgroundColor: campaign.wheel.screenColor || "#E60805",
          color: campaign.wheel.textColor || "fff",
          // backgroundImage: `url(${env + campaign.wheel.screenBg})` || "none",
          background: background,
          backgroundSize: backgroundSize
        }}
      >
        {campaign.wheel.logo && (
          <img
            className="screen-logo"
            src={env + campaign.wheel.logo}
            alt={campaign.title}
          ></img>
        )}
        <h3>{campaign.wheel.pageTitle}</h3>
        <SpinWheel
          collectUserData={campaign.collectUserData}
          wheel={campaign.wheel}
          slices={campaign.wheel.slices}
          campaignId={campaign.id}
          onStockDepletion={handleStockDepletion}
        />
      </main>
    </>
  );
}
