import { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MenuItem, TextField, Chip, Grid } from "@mui/material";
import agent from "../api/agent";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { GameDto } from "../models/gameDto";

export default function ListPlayers() {
  const [games, setGames] = useState<GameDto[]>([]);
  const [campaigns, setCampaigns] = useState<{id: string, name: string}[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>("")

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await agent.Games.list();
      setGames(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Oyuncular getirilemedi.");
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await agent.Campaigns.listLight();
      setCampaigns(response.map((campaign) => ({ id: campaign.id, name: campaign.title })));
    } catch (error) {
      console.log(error);
      toast.error("Kampanyalar getirilemedi.");
    }
  };

  useEffect(() => {
    fetchGames();
    fetchCampaigns();
  }, []);

  const handleCampaignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCampaignId(event.target.value);
  };

  const filteredGames = selectedCampaignId
    ? games.filter((game) => game.campaignId === selectedCampaignId)
    : games;

  const columns: GridColDef[] = [
    {
      field: "datePlayed",
      headerName: "Oynanma Tarihi",
      width: 200,
      renderCell: (params: any) => dayjs(params.row.datePlayed).format('DD.MM.YYYY HH:mm'),
    },
    {
      field: "prize",
      headerName: "Ödül",
      width: 200,
      renderCell: (params: any) =>
        params.row.isPrize ? (
          params.row.prize
        ) : (
          <Chip label="Kazanamadı" color="error" />
        ),
    },
    {
      field: "name",
      headerName: "Ad Soyad",
      width: 200,
    },
    {
      field: "gender",
      headerName: "Cinsiyet",
      width: 100,
      renderCell: (params: any) => 
        params.row.name !== ' ' && (        
        <Chip
          label={
            params.value === "1"
              ? "Erkek"
              : params.value === "2"
              ? "Kadın"
              : "Erkek"
          }
        />
      ),
    },
    {
      field: "email",
      headerName: "E-posta",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 140,
    },
    {
      field: "company",
      headerName: "Firma",
      width: 160,
    },
    {
      field: "campaignName",
      headerName: "Kampanya Adı",
      width: 200,
    },
  ];

  return (
    <>
      <AdminLayout title="Oyuncular">
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              select
              label="Kampanya Seç"
              value={selectedCampaignId}
              onChange={handleCampaignChange}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <DataGrid
          rows={filteredGames}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25]}
          checkboxSelection
        />
      </AdminLayout>
    </>
  );
}
