import styled from 'styled-components';

interface PointerPinProps {
  spinDuration: number;
  speedMode: boolean;
  pinColor: string;
  bgImage: string;
}

interface WheelContainerProps {
  decorated: boolean;
  imageUrl: string;
}

export const WheelContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'decorated',
      'imageUrl'      
    ].includes(prop),
})<WheelContainerProps>`
  position: relative;
  cursor: pointer;
  width: 350px;
  height: 350px;
  outline: none;
  -webkit-tap-highlight-color: transparent;

  & > div {
    height: 100%;
    width: 100%;
    max-height: unset;
    max-width: unset;
    transform: rotate(-45deg);
  }

  & > div:first-of-type > div::before {
    ${props => 
      props.decorated && `
        content: '';
        background-image: url('${props.imageUrl}');
        background-repeat: no-repeat;
        background-size: 340px 340px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(108.5deg);
        width: 340px;
        height: 340px;
        z-index: 6;
      `
    }
  }
`;

export const PointerPin = styled.span.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'spinDuration',
      'speedMode',
      'pinColor',
      'bgImage'
    ].includes(prop),
})<PointerPinProps>`
  position: absolute;
  top: -24px;
  left: 48%;
  width: 28px;
  height: 35px;
  border-radius: 50%;
  background-color: ${props => props.pinColor};
  ${props => props.bgImage && `
    background: none;
    background-image: url('${props.bgImage}');
    background-repeat: no-repeat;
    height: 52px;
    width: 40px;
    left: 46%;
    top: -30px;
    background-size: contain;
  `}
  z-index: 12;

  ${props => !props.bgImage && `
    &::after {
      position: absolute;
      content: '';
      width: 0px;
      height: 0px;
      bottom: -24px;
      border: 14px solid transparent;
      border-top: 24px solid ${props.pinColor};
    }
    
  `}

  &.spinning {
     ${props => {
      const totalDuration = props.spinDuration;

      if (props.speedMode) {
        const delay1 = 0.05 * totalDuration;
        const delay2 = 0.15 * totalDuration;
        const delay3 = 0.35 * totalDuration;
        const delay4 = 0.7 * totalDuration;
        const delay5 = 0.85 * totalDuration;

        const duration1 = 0.1 * totalDuration;
        const duration2 = 0.08 * totalDuration;
        const duration3 = 0.06 * totalDuration;
        const duration4 = 0.1 * totalDuration;
        const duration5 = 0.2 * totalDuration;

        return `
          animation-name: twitch, twitch, twitch, twitch, twitch;
          animation-delay: ${delay1}s, ${delay2}s, ${delay3}s, ${delay4}s, ${delay5}s;
          animation-duration: ${duration1}s, ${duration2}s, ${duration3}s, ${duration4}s, ${duration5}s;
          animation-iteration-count: 4, 8, 32, 8, 4;
        `;
      } else {
        const delay1 = 0.0714 * totalDuration;
        const delay2 = 0.214 * totalDuration;
        const delay3 = 0.357 * totalDuration;
        const delay4 = 0.786 * totalDuration;
        const delay5 = 0.929 * totalDuration;
  
        const duration1 = 0.2857 * totalDuration;
        const duration2 = 0.0714 * totalDuration;
        const duration3 = 0.0357 * totalDuration;
        const duration4 = 0.0714 * totalDuration;
        const duration5 = 0.2857 * totalDuration;
  
        return `
          animation-name: twitch, twitch, twitch, twitch, twitch;
          animation-delay: ${delay1}s, ${delay2}s, ${delay3}s, ${delay4}s, ${delay5}s;
          animation-duration: ${duration1}s, ${duration2}s, ${duration3}s, ${duration4}s, ${duration5}s;
          animation-iteration-count: 0.5, 2, 12, 2, 0.5;
        `;
      }
    }}
  }

  @keyframes twitch {
    0% { transform: rotate(0deg); }
    20% { transform: rotate(-10deg); }
    40% { transform: rotate(10deg); }
    60% { transform: rotate(-10deg); }
    80% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
  }
`;