import { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Campaign } from "../models/campaign";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';

export default function ListCampaigns() {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchCampaigns = async () => {
    setLoading(true);
    try {
      const response = await agent.Campaigns.list();
      const fetchedCampaigns = response;
      setCampaigns(fetchedCampaigns);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Kampanyalar getirilemedi.');
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      fetchCampaigns();
    }
  }, [location.state]);

  const moveRow = async (id: string, direction: "up" | "down") => {
    try {
      const response = await agent.Campaigns.listOrder(id, direction);
      const index = campaigns.findIndex((campaign) => campaign.id === id);
      if (index === -1) return;

      const newCampaigns = [...campaigns];
      const [movedCampaign] = newCampaigns.splice(index, 1);

      if (direction === "up" && index > 0) {
        newCampaigns.splice(index - 1, 0, movedCampaign);
      } else if (direction === "down" && index < newCampaigns.length) {
        newCampaigns.splice(index + 1, 0, movedCampaign);
      }

      // Update ListOrder
      const updatedCampaigns = newCampaigns.map((campaign, i) => ({
        ...campaign,
        listOrder: i + 1,
      }));

      setCampaigns(updatedCampaigns);

      const reorderedCampaigns = [
        {
          id: movedCampaign.id,
          listOrder: updatedCampaigns.find(c => c.id === movedCampaign.id)!.listOrder
        },
        {
          id:
            direction === "up"
              ? newCampaigns[index].id
              : newCampaigns[index + 1]?.id,
          listOrder:
            direction === "up"
              ? movedCampaign.listOrder
              : newCampaigns[index + 1]?.listOrder
        },
      ];
    } catch (error) {
      toast.error('Sıralama başarısız!');
    }
  };

  const handleDuplicateCampaign = async (id: string) => {
    const {value: campaignTitle} = await Swal.fire({
      title: 'Kampanyayı klonlamak istediğinizden emin misiniz?',
      text: 'Klonlama işlemi için yeni kampanya ismi giriniz',
      icon: 'warning',
      input: "text",
      inputLabel: "Yeni Kampanya İsmi",
      showCancelButton: true,
      confirmButtonText: 'Klonla',
      cancelButtonText: 'İptal',
    });
    
    if (campaignTitle) {
      try {
        const response = await agent.Campaigns.details(id);
        const campaignData: any = response;
        campaignData.wheel.slices.sort(
          (a: any, b: any) => a.listOrder - b.listOrder
        );
        const newCampaignId = uuid();
        const newWheelId = uuid();
        const newCampaignData = {
          ...campaignData,
          id: newCampaignId,
          title: campaignTitle,
          wheel: {
            ...campaignData.wheel,
            id: newWheelId,
            campaignId: newCampaignId,
            slices: campaignData.wheel.slices.map((slice: any, index: any) => ({
              ...slice,
              id: uuid(),
              wheelId: newWheelId,
            })),
          }
        }
        try {
          await agent.Campaigns.create(newCampaignData);
          const refreshCampaigns = await agent.Campaigns.list();
          setCampaigns(refreshCampaigns);
          toast.success('Kampanya başarıyla klonlandı: ' + campaignTitle);
        } catch (error) {
          toast.error('Kampanya klonlanırken bir hata oluştu.');
        }
      } catch (error) {
        toast.error('Kampanya bilgileri okunurken bir hata oluştu.');
      }
    } else {
      toast.error('Klonlamak için yeni kampanya ismi girmelisiniz.');
    }
  };

  const handleDeleteCampaign = (id: string) => {
    Swal.fire({
        title: 'Silmek istediğinizden emin misiniz?',
        text: 'Silme işlemini geri alamazsınız!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tamam',
        cancelButtonText: 'İptal',
    }).then((result) => {
        if (result.isConfirmed) {
            agent.Campaigns.delete(id).then(() => {
                setCampaigns([...campaigns.filter(x => x.id !== id)]);
                toast.success('Kampanya silindi!');
                setCampaigns([...campaigns.filter(x => x.id !== id)])
            }).catch((error) => {
                toast.error('Kampanya silinirken bir hata oluştu.');
            });
        }
    });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "listOrder", headerName: "Sıra", width: 70 },
    { field: "title", headerName: "Kampanya Adı", width: 160 },
    {
      field: "startDate",
      headerName: "Başlangıç",
      description: "Kampanyanın başlangıç tarihi.",
      width: 180,
      renderCell: (params: any) => dayjs(params.value as string).format("DD.MM.YYYY HH:mm"),
    },
    {
      field: "endDate",
      headerName: "Bitiş",
      description: "Kampanyanın bitiş tarihi.",
      width: 180,
      renderCell: (params: any) => dayjs(params.value as string).format("DD.MM.YYYY HH:mm"),
    },
    {
      field: "isActive",
      headerName: "Yayında Mı?",
      width: 140,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Yayında" : "Yayında değil"}
          color={params.value ? "success" : "error"}
        />
      ),
    },
    {
      field: "order",
      headerName: "Sıralama",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => moveRow(params.row.id, "up")}
            disabled={params.row.listOrder === 1}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={() => moveRow(params.row.id, "down")}
            disabled={params.row.listOrder === campaigns.length}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Eylemler",
      width: 360,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => navigate(`/campaigns/${params.row.id}`)}
            style={{marginRight: "15px"}}
          >
            Düzenle
          </Button>
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => window.open(`/game/${params.row.id}`, '_blank')}
            style={{marginRight: "15px"}}
          >
            Oyun
          </Button>
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={() => handleDuplicateCampaign(params.row.id)}
            style={{marginRight: "15px"}}
          >
            Klonla
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleDeleteCampaign(params.row.id)}
          >
            Sil
          </Button>
      </>
      ),
    },
  ];

  return (
    <>
      <AdminLayout title="Kampanyalar">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/campaigns/new")}
          sx={{ mb: 2 }}
        >
          Kampanya Ekle
        </Button>
        <DataGrid
          rows={campaigns}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25]}
          checkboxSelection
        />
      </AdminLayout>
    </>
  );
}