import { useEffect, useState, useRef } from "react";
import { Wheel } from "../../app/utils/react-custom-roulette/components/Wheel";
import "./SpinWheel.css";
import SpinEffect from "./SpinEffect";
import { WheelProps } from "../../app/models/wheelProps";
import { getWinningSlice } from "../../app/utils/getWinningSlice";
import {
  Modal,
  Box,
  Typography,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormGroup,
  Checkbox,
} from "@mui/material";
import Swal from "sweetalert2";
import {v4 as uuid} from "uuid";
import agent from "../../app/api/agent";
import { Player } from "../../app/models/player";
import { env } from "../../app/utils/env";
import { PointerPin, WheelContainer } from "./wheelStyles";

interface SpinWheelProps {
  collectUserData: number;
  wheel: WheelProps['wheel'];
  slices: WheelProps['slices'];
  campaignId: string;
  onStockDepletion: () => void;
}

const SpinWheel: React.FC<SpinWheelProps> = ({ collectUserData, wheel, slices, campaignId, onStockDepletion }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [effect, setEffect] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [resetWheel, setResetWheel] = useState(false);
  const [player, setPlayer] = useState<Player | null>(null);
  const [slicesState, setSlicesState] = useState(slices);
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    company: "",
    gender: 1,
  });
  const [dataCollected, setDataCollected] = useState(false);

  let playerId = null;

  const audioRef = useRef(new Audio(`${process.env.PUBLIC_URL}/assets/wheel-turn-effect.mp3`));  

  const handleSpinClick = () => {
    setEffect(false);
    if (!mustSpin) {
      const validSlices = slicesState.filter(slice => slice.probability! > 0);
      let winnerId: string;
      if (validSlices.length > 0) {
        winnerId = getWinningSlice(validSlices);
      } else {
        winnerId = getWinningSlice(slicesState);
      }
      
      const winnerSlice = slicesState.find(slice => slice.id == winnerId);
      const newPrizeNumber = slicesState.indexOf(winnerSlice!);
      setPrizeNumber(newPrizeNumber);
      setMustSpin(true);
    }
  };

  useEffect(() => {
    if (mustSpin) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [mustSpin]);

  const handleClose = () => {
    if (collectUserData != 2 && !dataCollected) {
      setPlayer(null);
    }
    setOpenModal(false);
    setResetWheel(true);
    setEffect(true);
    setFormValues({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      gender: 1,
    });
  };

  useEffect(() => {
    if (resetWheel) {
      setMustSpin(false);
      setResetWheel(false);
    }
  }, [resetWheel]);

  const onWheelStop = async () => {
    setMustSpin(false);

    const selectedSlice = slices[prizeNumber];
    const oldStock = slicesState[prizeNumber].stock;

    Swal.fire({
      title: selectedSlice.prizeValue,
      text: selectedSlice.isPrize
        ? `Tebrikler! Hediye kazandınız! ${collectUserData === 3 ? "Hediyenizi almak için kayıt oluşturun." : ""}`
        : "Maalesef kazanamadınız.",
      icon: selectedSlice.isPrize ? "success" : "error",
      imageUrl: env + selectedSlice.prizeImage || undefined,
      imageWidth: 200,
      showCancelButton: collectUserData === 3 ? true : false,
      showConfirmButton: collectUserData === 3 ? true : false,
      confirmButtonText: "Kullanıcı Formu",
      cancelButtonText: "Başa Dön",
      customClass: {
        popup: 'swal-container',
        icon: 'swal-icon',
        image: selectedSlice.prizeImage ? 'swal-image' : 'd-none'
      },
      didOpen: () => {
        if (collectUserData != 1) {
          document.addEventListener('click', () => {
            Swal.close();
          });
        }
      },
    }).then(async (result) => {
      playerId = uuid();
      const gameData = {
        id: uuid(),
        prize: selectedSlice.prizeValue,
        isPrize: selectedSlice.isPrize,
        datePlayed: new Date().toISOString(),
        campaignId: campaignId,
        playerId: playerId,
        player: {
          id: playerId,
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          company: "",
          gender: formValues.gender === 1 ? 1 : 2,
          campaignId: campaignId
        }
      };
      if (collectUserData === 2 && player != null) {
        gameData.player = player;
        gameData.playerId = player.id;
      }
      if (selectedSlice.isPrize && oldStock! > 0) {
        const sliceData = {
          ...selectedSlice,
          stock: oldStock! - 1,
          wheelId: wheel.id
        }
        const updatedSlices = slicesState.map(slice => 
          slice.id === sliceData.id 
            ? { ...slice, stock: sliceData.stock }
            : slice
        );
        setSlicesState(updatedSlices);

        await agent.Slices.update(sliceData);

        onStockDepletion();

        // Check and update probability if stock becomes 0
        if (sliceData.stock <= 0) {
          const updatedSlicesWithProbability = updatedSlices.map(slice => 
            slice.id === sliceData.id 
              ? { ...slice, probability: 0 }
              : slice
          );
          setSlicesState(updatedSlicesWithProbability);
          
        }
      }
      setPlayer(gameData.player);
      await agent.Games.create(gameData);
      if (collectUserData == 3 && result.isConfirmed) {
        setOpenModal(true);
      } else {
        setPlayer(null);
        setResetWheel(true);
        setEffect(true);
        setDataCollected(false);
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === 'gender' ? parseInt(value) : value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const playerData : any = {
      ...player,
      campaignId: campaignId,
      ...formValues      
    };
    if (collectUserData === 2) {
      playerId = uuid();
      playerData.id = playerId;
      setPlayer(playerData);
      setDataCollected(true);
    } else {
      await agent.Players.update(playerData);
    }

    handleClose();
  };

  return (
    <>
      <WheelContainer
        className={openModal ? 'wheel-container disable-clicks' : 'wheel-container'}
        decorated={wheel.spinnerDecoration}
        imageUrl={wheel.spinnerImage ? env + wheel.spinnerImage : `${process.env.PUBLIC_URL}/assets/wheel-decoration.png`}
        onClick={handleSpinClick}
      >
        <PointerPin
          className={"pointer-pin " + (mustSpin ? "spinning" : "")}
          spinDuration={wheel.spinDuration}
          speedMode={wheel.spinSpeed === 2 ? true : false}
          pinColor={wheel.wheelPinColor || `#eeeeee`}
          bgImage={wheel.wheelPinImage && env + wheel.wheelPinImage}
        />
        <img src={wheel.circleImage ? env + wheel.circleImage : `${process.env.PUBLIC_URL}/assets/eddra-emblem.png`} className="center-img" alt="" />
        <Wheel
          mustStartSpinning={mustSpin}
          onStopSpinning={() => onWheelStop()}
          prizeNumber={prizeNumber}
          spinDuration={wheel.spinDuration / 10}
          data={slicesState.map((slice) => ({
            option: slice.prizeValue,
            ...(slice.wedgeImage && {
              image: {
                uri: env + slice.wedgeImage,
                sizeMultiplier: 1.7,
                offsetY: 80,
              },
            }),
            probability: slice.probability ? parseFloat(slice.probability.toFixed(3)) : undefined,
          }))}
          startingOptionIndex={0}
          backgroundColors={[
            wheel.colorFirst || "#fff",
            wheel.colorSecond || "#E60F0F",
          ]}
          textColors={[
            wheel.wheelTextColor || "#3e3e3e",
            wheel.wheelTextColor2 || "#fff",
          ]}
          outerBorderColor={wheel.wheelBorderColor || "#E60F0F"}
          outerBorderWidth={10}
          innerRadius={20}
          radiusLineWidth={wheel.radiusLine ? 5 : 0}
          radiusLineColor={wheel.radiusLineColor || "e60f0f"}
          backwardsAnimation={!wheel.clockwise}
          noAnimationSteps={wheel.spinSpeed === 2 ? true : false}
        />
        {effect && wheel.spinnerEffect && (
          <SpinEffect mustSpin={mustSpin} dataLength={slices.length} />
        )}
      </WheelContainer>
      <Modal
        open={collectUserData === 2 && !dataCollected ? true : openModal}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="game-modal modal"
      >
        <Box
          sx={{
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            outline: "none",
          }}
        >
          <p>Lütfen bilgilerinizi giriniz.</p>
          <form className="login-form" onSubmit={handleSubmit}>
            <Grid container sx={{ justifyContent: "space-between" }}>
              <Grid item xs={5}>
                <TextField
                  label="Adınız"
                  name="firstName"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Soyadınız"
                  name="lastName"
                  type="text"
                  fullWidth
                  variant="outlined"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  required
                />
              </Grid>
            </Grid>

            {/* <TextField
              label="E-Posta"
              name="email"
              type="email"
              fullWidth
              variant="outlined"
              value={formValues.email}
              onChange={handleInputChange}
            /> */}

            <TextField
              label="Telefon"
              name="phone"
              type="text"
              fullWidth
              variant="outlined"
              value={formValues.phone}
              onChange={handleInputChange}
              required
            />
            <TextField
              label="Firma Adı"
              name="company"
              type="text"
              fullWidth
              variant="outlined"
              value={formValues.company}
              onChange={handleInputChange}
              required
            />

            {/* <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
              Cinsiyet
            </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              value={formValues.gender}
              onChange={handleInputChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Erkek"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Kadın"
              />
            </RadioGroup> */}

            <FormGroup className="form-control-contracts">
              <FormControlLabel
                required
                control={<Checkbox />}
                label={
                  <small><a href="https://www.tokenflex.com.tr/yasal-metin-bilgilendirme/tokenflex-kurumsal-musteri-temsilcisi-calisani-aydinlatma-metni" target="_blank">TokenFlex Kurumsal Müşteri Temsilcisi/Çalışanı Aydınlatma Metni</a>’ni okudum, anladım.</small>
                }
              />
              <FormControlLabel
                required
                control={<Checkbox />}
                label={
                  <small>Yukarıda verdiğim iletişim bilgilerime tanıtım, reklam, kampanya ve benzeri pazarlama faaliyetleri kapsamında ticari elektronik ileti gönderilmesine ve kimlik ve iletişim verilerimin bu amaçla işlenmesine izin veriyorum.</small>
                }
              />
            </FormGroup>
            <Button
              variant="contained"
              type="submit"
              sx={{ my: 4, backgroundColor: "#FAB406" }}
            >
              Gönder
            </Button>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default SpinWheel;
