// src/app/utils/initializeCampaign.ts
import dayjs from "dayjs";
import { Campaign } from "../models/campaign";

export const initializeNewCampaign = (): Campaign => {
  return {
    id: "",
    title: "",
    startDate: dayjs(),
    endDate: dayjs(),
    isActive: true,
    collectUserData: 1,
    companyName: "",
    venue: "",
    description: "",
    listOrder: 0,
    customCSS: "",
    wheel: {
      id: "",
      pageTitle: "",
      motto: "",
      logo: "",
      screenBg: "",
      screenCenterBg: "",
      screenColor: '#fff',
      textColor: '#000',
      colorFirst: '#fff',
      colorSecond: '#000',
      circleImage: "",
      wheelTextColor: "red",
      wheelTextColor2: "blue",
      wheelBorderColor: "#e60f0f",
      wheelPinColor: "#eeeeee",
      wheelPinImage: "",
      radiusLine: false,
      radiusLineColor: "#e60f0f",
      spinnerDecoration: true,
      spinnerImage: "",
      spinnerEffect: true,
      clockwise: true,
      spinDuration: 7,
      spinSpeed: 1,
      sliceAmount: 6,
      slices: Array(6).fill({
        id: "",
        prizeValue: "",
        prizeImage: "",
        wedgeImage: "",
        isPrize: true,
        probability: null,
        stock: null,
        listOrder: null,
      }),
      campaignId: ""
    },
  };
};

export const initializeCampaignData = (campaignData: Campaign): Campaign => {
  return {
    ...campaignData,
    startDate: dayjs(campaignData.startDate),
    endDate: dayjs(campaignData.endDate),
  };
};