import React, { useState, useEffect, SyntheticEvent } from "react";
import AdminLayout from "../layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Alert,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/tr";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import AdminField from "../layout/AdminField";
import {
  initializeNewCampaign,
  initializeCampaignData,
} from "../utils/initializeCampaign";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";
import { toast } from "react-toastify";
import ImagePickerModal from "../layout/ImagePickerModal";
import Swal from "sweetalert2";

export default function CampaignDetails() {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<any | null>(null);
  const [titleInput, setTitleInput] = useState<string>("");
  const [tabValue, setTabValue] = useState(0);
  const [errors, setErrors] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      agent.Campaigns.details(id).then((response) => {
        const campaignData: any = response;
        campaignData.wheel.slices.sort(
          (a: any, b: any) => a.listOrder - b.listOrder
        );
        setCampaign(initializeCampaignData(campaignData));
        setTitleInput(campaignData.title);
      });
    } else {
      const newCampaign = initializeNewCampaign();
      newCampaign.wheel.slices.forEach((slice, index) => {
        slice.listOrder = index + 1;
      });
      setCampaign(newCampaign);
      setTitleInput(newCampaign.title);
    }
  }, [id]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSliceAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSliceAmount = parseInt(event.target.value);
    const currentSliceAmount = campaign?.wheel.slices.length || 6;

    if (newSliceAmount > currentSliceAmount) {
      const newSlices = Array(newSliceAmount - currentSliceAmount)
        .fill(null)
        .map((_, index) => ({
          id: uuid(),
          prizeValue: "",
          prizeImage: "",
          wedgeImage: "",
          isPrize: true,
          probability: null,
          stock: null,
          listOrder: currentSliceAmount + index + 1,
          wheelId: campaign?.wheel.Id || null,
        }));
      setCampaign({
        ...campaign!,
        wheel: {
          ...campaign!.wheel,
          sliceAmount: newSliceAmount,
          slices: [...campaign!.wheel.slices, ...newSlices],
        },
      });
    } else {
      setCampaign({
        ...campaign!,
        wheel: {
          ...campaign!.wheel,
          sliceAmount: newSliceAmount,
          slices: campaign!.wheel.slices.slice(0, newSliceAmount),
        },
      });
    }
  };

  const handleSliceChange = (
    index: number,
    field: string,
    value: string | boolean | number | null
  ) => {
    if (!campaign) return;

    const newSlices = [...campaign.wheel.slices];
    newSlices[index] = { ...newSlices[index], [field]: value };
    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, slices: newSlices },
    });
  };

  const handleWheelChange = (field: string, value: any) => {
    if (!campaign) return;

    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, [field]: value },
    });
  };

  const validateForm = () => {
    let tempErrors: any = {};

    if (!titleInput) tempErrors.titleInput = "Kampanya Başlığı zorunludur";
    if (campaign?.wheel.slices.some((slice: any) => !slice.prizeValue)) {
      tempErrors.slices = "Ödül değeri çark dilimi için zorunludur.";
    }
    if (
      campaign?.wheel.slices.some(
        (slice: any) => slice.probability! > 1 && slice.probability! < 0
      )
    ) {
      tempErrors.slices = "Olasılık 0'dan küçük ve 1'den büyük olamaz.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    const campaignId = campaign?.id || uuid();
    const wheelId = campaign?.wheel.id || uuid();

    const campaignData = {
      ...campaign,
      id: campaignId,
      title: titleInput,
      startDate: (campaign!.startDate as Dayjs).toISOString(),
      endDate: (campaign!.endDate as Dayjs).toISOString(),
      collectUserData: campaign!.collectUserData === 1 ? 1 : (campaign!.collectUserData === 2 ? 2 : 3),
      wheel: {
        ...campaign!.wheel,
        id: wheelId,
        campaignId: campaignId,
        spinSpeed: campaign!.wheel.spinSpeed === 1 ? 1 : 2,
        slices: campaign!.wheel.slices.map((slice: any, index: any) => ({
          ...slice,
          id: slice.id || uuid(),
          wheelId: wheelId,
          probability: slice.probability !== null ? slice.probability : null,
          stock: slice.stock !== null ? slice.stock : null,
          listOrder: index + 1,
        })),
      },
    };

    try {
      if (id) {
        await agent.Campaigns.update(campaignData);
      } else {
        await agent.Campaigns.create(campaignData);
      }
      navigate("/campaigns", { state: { reload: true } });
    } catch (error: any) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
      } else {
        console.error("Kampanya düzenlenirken bir hata oluştu:", error.message);
      }
    }
  };

  const openImagePicker = (field: string) => {
    setActiveField(field);
    setOpenModal(true);
  };

  const handleImageSelect = (url: string) => {
    if (!campaign || !activeField) return;

    if (activeField.startsWith("slice")) {
      const [_, indexStr, sliceField] = activeField.split("-");
      const index = parseInt(indexStr, 10);
      handleSliceChange(index, sliceField, url);
    } else {
      handleWheelChange(activeField, url);
    }

    setOpenModal(false);
  };

  const moveSlice = (index: number, direction: "up" | "down") => {
    if (!campaign) return;

    const newSlices = [...campaign.wheel.slices];
    const sliceToMove = newSlices[index];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Swap slices
    newSlices[index] = newSlices[targetIndex];
    newSlices[targetIndex] = sliceToMove;

    // Update listOrder
    newSlices[index].listOrder = index + 1;
    newSlices[targetIndex].listOrder = targetIndex + 1;

    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, slices: newSlices },
    });
  };

  const handleDeleteGameData = () => {
    Swal.fire({
      title: "Silmek istediğinizden emin misiniz?",
      text: "Bu kampanya için kaydedilmiş tüm oyun ve oyuncu verileri silinecektir. Silme işlemini geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Tamam",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        agent.Players.deleteAll(id)
          .then(() => {
            toast.success("Tüm oyun ve oyuncu verileri silindi!");
          })
          .catch((error) => {
            toast.error("Veriler silinirken bir hata oluştu.");
          });
      }
    });
  };

  if (!campaign) return null;

  return (
    <AdminLayout title={campaign.title || "Kampanya Ekle"}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/campaigns")}
        sx={{ mr: 3 }}
      >
        Kampanyalara Dön
      </Button>
      {id && (
        <>
          <Button
            variant="contained"
            startIcon={<AnalyticsIcon />}
            color="success"
            onClick={() => navigate(`/statistics/${id}`)}
            sx={{ mr: 2 }}
          >
            İstatistikler
          </Button>
          <Button
            variant="contained"
            startIcon={<VideogameAssetIcon />}
            color="warning"
            onClick={() => window.open(`/game/${id}`, "_blank")}
            sx={{ mr: 2 }}
          >
            Oyun Ekranı
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleDeleteGameData}
          >
            Oyun ve Oyuncu Verilerini Sil
          </Button>
        </>
      )}
      {campaign.id ? (
        <Alert sx={{ my: 3 }} severity="warning">
          Kampanyanızı oluşturdunuz. Artık <strong>Çark Dilim Alanları</strong>{" "}
          sekmesindeki <strong>Dilim Sayısı</strong> oluşturulmuş kampanya
          içinde değiştirilemez.
        </Alert>
      ) : (
        <Alert sx={{ my: 3 }} severity="info">
          Temel kampanya bilgilerinizi girdikten sonra kaydetmeden önce lütfen
          yan sekmelerdeki bilgileri de düzenleyiniz. Dilerseniz{" "}
          <strong>Dilim Sayısı</strong> hariç bilgilerinizi sonradan da
          düzenleyebilirsiniz. <strong>Çark Dilim Alanları</strong>nda yer alan{" "}
          <strong>Dilim Sayısı</strong> kampanya oluşturulduktan sonra
          değiştirilemez. Kampanyalarınızı oluşturduktan sonra ödül
          istatistiklerini inceleyebileceğiniz <strong>İstatistikler</strong>{" "}
          butonu hemen yukarıda yer alacaktır.
        </Alert>
      )}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          label="Kampanya Alanları"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Çarkıfelek Alanları"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
        <Tab
          label="Çark Dilim Alanları"
          id="simple-tab-2"
          aria-controls="simple-tabpanel-2"
        />
      </Tabs>
      <Divider sx={{ mb: 5 }} />
      <form onSubmit={handleSubmit}>
        <div
          role="tabpanel"
          hidden={tabValue !== 0}
          id="simple-tabpanel-0"
          aria-labelledby="simple-tab-0"
        >
          <Grid container spacing={4}>
            <AdminField
              label="Kampanya Başlığı"
              value={titleInput}
              onChange={(value) => setTitleInput(value)}
              error={!!errors.titleInput}
              errorMessage={errors.titleInput}
            />
            <AdminField
              label="Başlangıç Tarihi"
              type="date"
              value={campaign.startDate}
              onChange={(newValue) =>
                setCampaign({ ...campaign, startDate: newValue || dayjs() })
              }
              error={!!errors.startDate}
              errorMessage={errors.startDate}
            />
            <AdminField
              label="Bitiş Tarihi"
              type="date"
              value={campaign.endDate}
              onChange={(newValue) =>
                setCampaign({ ...campaign, endDate: newValue || dayjs() })
              }
              error={!!errors.startDate}
              errorMessage={errors.startDate}
            />
            <AdminField
              label="Yayında mı?"
              type="switch"
              value={campaign.isActive}
              onChange={(value) =>
                setCampaign({ ...campaign, isActive: value })
              }
            />
            <AdminField
              label="Kullanıcı kaydı"
              type="radio"
              value={campaign.collectUserData}
              helperText="Oyun başında veya sonunda kullanıcı bilgileri ile kayıt almak istiyorsanız bu ayarı düzenleyiniz"
              onChange={(value) =>
                setCampaign({ ...campaign, collectUserData: parseInt(value) })
              }
              selectOptions={[
                { label: "Kayıt Alma", value: 1 },
                { label: "Oyun Başında", value: 2 },
                { label: "Oyun Sonunda", value: 3 },
              ]}
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Ek Bilgi Alanları
          </Typography>

          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Açıklama"
              value={campaign.description}
              helperText="Etkinliğin açıklama metnini yazınız"
              onChange={(value) =>
                setCampaign({ ...campaign, description: value })
              }
            />
            <AdminField
              label="Firma Adı"
              value={campaign.companyName}
              helperText="Etkinliğin yapılacağı firmanın adını yazınız"
              onChange={(value) =>
                setCampaign({ ...campaign, companyName: value })
              }
            />
            <AdminField
              label="Etkinlik Yeri"
              value={campaign.venue}
              helperText="Etkinliğin yapılacağı yeri yazınız"
              onChange={(value) => setCampaign({ ...campaign, venue: value })}
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Özelleştirme
          </Typography>
          
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Özel CSS"
              value={campaign.customCSS}
              helperText="Eğer oyun ekranında sadece bu kampanya için geçerli olacak görselleştirme uygulayacaksanız bu alan içinde özel CSS kullanınız."
              onChange={(value) => setCampaign({ ...campaign, customCSS: value })}
              multiline
              rows={10}
            />
          </Grid>
        </div>
        {campaign.wheel && (
          <>
            <div
              role="tabpanel"
              hidden={tabValue !== 1}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çarkıfelek Ekranı Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Ekran Başlığı"
                  value={campaign.wheel.pageTitle}
                  onChange={(value) => handleWheelChange("pageTitle", value)}
                  helperText="Çarkıfelek ekranında yer alacak başlığı yazınız"
                />
                <AdminField
                  label="Ekran Mottosu"
                  value={campaign.wheel.motto}
                  onChange={(value) => handleWheelChange("motto", value)}
                  helperText="Çarkıfelek ekranı çark altında yer alacak motto yazısını yazınız"
                />
                <AdminField
                  label="Ekran Logosu"
                  value={campaign.wheel.logo}
                  onChange={(value) => handleWheelChange("logo", value)}
                  helperText="Çarkıfelek ekranı çark üstünde yer alacak logoyu ekleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("logo")}
                />
                <AdminField
                  label="Ekran Arkaplan Görseli"
                  value={campaign.wheel.screenBg}
                  onChange={(value) => handleWheelChange("screenBg", value)}
                  helperText="Çarkıfelek ekranı arkaplan desen görselini yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("screenBg")}
                />
                <AdminField
                  label="Çark Altı Ek Görseli"
                  value={campaign.wheel.screenCenterBg}
                  onChange={(value) =>
                    handleWheelChange("screenCenterBg", value)
                  }
                  helperText="Çarkın altında ortada yer alacak ek görseli yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("screenCenterBg")}
                />
                <AdminField
                  label="Ekran Arkaplan Rengi"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.screenColor}
                  onChange={(value) => handleWheelChange("screenColor", value)}
                  helperText="Çarkıfelek ekranı arkaplan rengini seçiniz"
                />
                <AdminField
                  label="Ekran Yazı Rengi"
                  type="color"
                  inline
                  value={campaign.wheel.textColor}
                  onChange={(value) => handleWheelChange("textColor", value)}
                  helperText="Çarkıfelek ekranı yazı rengini seçiniz"
                />
              </Grid>
              <Divider sx={{ mb: 5 }} />
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çark Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Çark Pin Görseli"
                  value={campaign.wheel.wheelPinImage}
                  onChange={(value) =>
                    handleWheelChange("wheelPinImage", value)
                  }
                  helperText="Çark pinini ayrı görsel olarak kullanacaksanız buradan ekleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("wheelPinImage")}
                />
                <AdminField
                  label="Çark Ortası Görseli"
                  value={campaign.wheel.circleImage}
                  onChange={(value) => handleWheelChange("circleImage", value)}
                  helperText="Çarkın ortasında yer alacak logo görselini yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("circleImage")}
                />
                <AdminField
                  label="Çark Dekoru"
                  type="switch"
                  value={campaign.wheel.spinnerDecoration}
                  onChange={(value) =>
                    handleWheelChange("spinnerDecoration", value)
                  }
                  helperText="Çarkın etrafındaki dekoru buradan açıp kapatabilirsiniz"
                />
                <AdminField
                  label="Çark Dekoru Görseli"
                  value={campaign.wheel.spinnerImage}
                  onChange={(value) => handleWheelChange("spinnerImage", value)}
                  helperText="Çark dekoru kullanıyorsanız standart görseli değiştirmek için buradan yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("spinnerImage")}
                />
                <AdminField
                  label="Çark Efekti"
                  type="switch"
                  value={campaign.wheel.spinnerEffect}
                  onChange={(value) =>
                    handleWheelChange("spinnerEffect", value)
                  }
                  helperText="Çarkın parlama efektini buradan açıp kapatabilirsiniz"
                />
                <AdminField
                  label="Saat Yönünde Dönüş"
                  type="switch"
                  value={campaign.wheel.clockwise}
                  onChange={(value) => handleWheelChange("clockwise", value)}
                  helperText="Çarkın saat yönünde veya tersinde dönüşünü buradan ayarlayabilirsiniz"
                />
                <AdminField
                  label="Dönüş Süresi"
                  value={campaign.wheel.spinDuration}
                  type="number"
                  onChange={(value) => handleWheelChange("spinDuration", value)}
                  helperText="Çarkın kaç saniye döneceğini rakam olarak giriniz"
                />
                <AdminField
                  label="Dönüş Hızı"
                  type="radio"
                  value={campaign.wheel.spinSpeed}
                  onChange={(value) =>
                    handleWheelChange("spinSpeed", parseInt(value))
                  }
                  helperText="Çarkın dönüş hızını ayarlayınız."
                  selectOptions={[
                    { label: "Yavaş", value: 1 },
                    { label: "Hızlı", value: 2 },
                  ]}
                />
              </Grid>
              <Divider sx={{ mb: 5 }} />
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çark Renk Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Çark Rengi 1"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.colorFirst}
                  onChange={(value) => handleWheelChange("colorFirst", value)}
                  helperText="Çarkın ilk rengini giriniz"
                />
                <AdminField
                  label="Çark Rengi 2"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.colorSecond}
                  onChange={(value) => handleWheelChange("colorSecond", value)}
                  helperText="Çarkın ikinci rengini giriniz"
                />
                <AdminField
                  label="Çark Yazı Rengi 1"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.wheelTextColor || "#000"}
                  onChange={(value) =>
                    handleWheelChange("wheelTextColor", value)
                  }
                  helperText="Çarkı ilk yazı rengini giriniz"
                />
                <AdminField
                  label="Çark Yazı Rengi 2"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.wheelTextColor2 || "#fff"}
                  onChange={(value) =>
                    handleWheelChange("wheelTextColor2", value)
                  }
                  helperText="Çarkı ikinci yazı rengini giriniz"
                />
                <AdminField
                  label="Çark Kenar Rengi"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.wheelBorderColor || "#e60f0f"}
                  onChange={(value) =>
                    handleWheelChange("wheelBorderColor", value)
                  }
                  helperText="Çark çevresindeki kenar rengini giriniz"
                />
                <AdminField
                  label="Çark Pin Rengi"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.wheelPinColor || "#eeeeee"}
                  onChange={(value) =>
                    handleWheelChange("wheelPinColor", value)
                  }
                  helperText="Çark pininin rengini giriniz"
                />
                <AdminField
                  label="Çark Dilim Arası Çizgisi"
                  type="switch"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.radiusLine}
                  onChange={(value) => handleWheelChange("radiusLine", value)}
                  helperText="Çarkın dilimleri arasında çizgi kullanacaksanız buradan aktive ediniz"
                />
                <AdminField
                  label="Çark Dilim Arası Çizgi Rengi"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.radiusLineColor || "#e60f0f"}
                  onChange={(value) =>
                    handleWheelChange("radiusLineColor", value)
                  }
                  helperText="Çark dilimleri arasında çizgi kullanıyorsanız buradan rengini giriniz"
                />
              </Grid>
            </div>
            <div
              role="tabpanel"
              hidden={tabValue !== 2}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tab-2"
            >
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Dilim Sayısı"
                  type="select"
                  value={campaign.wheel.sliceAmount}
                  onChange={(value) =>
                    handleSliceAmountChange({
                      target: { value: value.toString() },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  disabled={id != null}
                  helperText={
                    id != null
                      ? "Dilim sayısı kampanya oluşturulduktan sonra değiştirilemez."
                      : "Çarkta kaç dilim olacağını seçiniz"
                  }
                  selectOptions={[
                    { label: "6", value: 6 },
                    { label: "8", value: 8 },
                    { label: "10", value: 10 },
                  ]}
                />

                <Grid item xs={2}>
                  <Typography variant="subtitle2">Dilimler</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Card sx={{ mb: 2 }}>
                    <CardContent>
                      <Alert sx={{ my: 3 }} severity="info">
                        Dilimlerin <strong>Olasılık</strong> değerlerini{" "}
                        <strong>0</strong> ile <strong>1</strong> arasında ve{" "}
                        <strong>virgül</strong> ile ondalık değer olarak
                        giriniz. Değerlerin toplamı <strong>1</strong>'e eşit
                        olmalıdır. <strong>Örnek:</strong> 6 adet dilim için
                        olasılıklar; 0,1, 0,2, 0,1, 0,3 , 0,15, 0,15.
                      </Alert>
                      <Alert sx={{ my: 3 }} severity="error">
                        <strong>Önemli!:</strong> Eğer{" "}
                        <strong>stok kontrolü</strong> olan bir oyun
                        kurguluyorsanız yani herhangi bir dilimin stok değerini
                        girdiyseniz{" "}
                        <strong>
                          hiçbir Olasılık alanını boş bırakmayınız
                        </strong>
                        . Aksi halde stok düşüşüyle birlikte yeniden dağıtılan
                        olasılık hesapları boş bırakılan her bir alan için
                        sıfıra düşecektir. Stok sınırı yer alan oyunlarda her
                        bir dilime olasılık değeri atamanız önemlidir.
                      </Alert>
                      {Array.isArray(campaign.wheel.slices) &&
                        campaign.wheel.slices.map((slice: any, index: any) => (
                          <div key={slice.id || index}>
                            <h4>Dilim {index + 1}</h4>
                            <Grid container spacing={4}>
                              <AdminField
                                type="disabled"
                                value={slice.listOrder}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "listOrder", value)
                                }
                              />

                              <Grid item>
                                <IconButton
                                  aria-label="move slice up"
                                  onClick={() => moveSlice(index, "up")}
                                  disabled={index === 0}
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="move slice down"
                                  onClick={() => moveSlice(index, "down")}
                                  disabled={
                                    index === campaign.wheel.slices.length - 1
                                  }
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>
                              </Grid>

                              <AdminField
                                label="Ödül Adı"
                                value={slice.prizeValue}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "prizeValue", value)
                                }
                                error={!!errors.slices}
                                errorMessage={errors.slices}
                              />
                              <AdminField
                                label="Olasılık"
                                type="decimal"
                                value={
                                  slice.probability !== null
                                    ? slice.probability
                                    : ""
                                }
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(
                                    index,
                                    "probability",
                                    value !== "" ? parseFloat(value) : null
                                  )
                                }
                              />
                              <AdminField
                                label="Stok"
                                type="number"
                                value={slice.stock !== null ? slice.stock : ""}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(
                                    index,
                                    "stock",
                                    value !== "" ? parseInt(value) : null
                                  )
                                }
                              />
                              <Grid item xs={2}></Grid>
                              <AdminField
                                label="Dilim Görseli"
                                value={slice.wedgeImage}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "wedgeImage", value)
                                }
                                helperText="Dilim için görsel ekleyiniz"
                                type="button"
                                buttonText="Galeri'den Seç"
                                onClick={() =>
                                  openImagePicker(`slice-${index}-wedgeImage`)
                                }
                              />
                              <AdminField
                                label="Ödül Görseli"
                                value={slice.prizeImage}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "prizeImage", value)
                                }
                                helperText="Ödül için görsel ekleyiniz"
                                type="button"
                                buttonText="Galeri'den Seç"
                                onClick={() =>
                                  openImagePicker(`slice-${index}-prizeImage`)
                                }
                              />
                              <AdminField
                                label="Ödül mü?"
                                type="switch"
                                value={slice.isPrize}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "isPrize", value)
                                }
                                helperText="Yalnızca pas veya İflas dilimi ise kapatınız"
                              />
                            </Grid>
                            <Divider sx={{ my: 3 }} />
                          </div>
                        ))}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        <Divider />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ my: 4 }}
        >
          Kaydet
        </Button>
      </form>
      <ImagePickerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSelect={handleImageSelect}
      />
    </AdminLayout>
  );
}
